import { Component, Vue, Mixins } from 'vue-property-decorator';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import template from './StatsCP.Template.vue';
import { ApiService } from '@/services/base/ApiService';
import { ApiHelper } from '@/services/ApiHelper';
import { FileContentResult } from '@/shared/models';

@Component({
    ...template,
    name: 'StatsCP',
})
export default class StatsCP extends Mixins(GrilleMixin) {
    public loading: boolean = false;
    public dernierExportBase: { nom: string, dateCreation: string } = null;

    // Définition de refs.
    public $refs!: Vue['$refs'] & {
        confirm: {
            open: ((
                title: string | null,
                message: string | null,
                options: { color?: string; width?: number; zIndex?: number }
            ) => Promise<boolean>);
        };
    };

    /**
     * Crée une instance de Reporting.
     * @memberof Reporting
     */
    constructor() {
        super();
    }

    /**
     * Export de base quotidien au format Excel.
     * @memberof Reporting
     */
    public genererStatsCP(): void {
        const apiExport = new ApiService<FileContentResult>(`/reporting/genererStatsCP`);
        this.loading = true;
        apiExport.getAll()
            .then((reponse: any) => {
                ApiHelper.createAndDownloadBlobFile(reponse.data as FileContentResult);
            })
            .finally(() => (this.loading = false));
    }
}
